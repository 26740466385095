import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export const InfoDialog = ({
  dlgOpen,
  title,
  type,
  disciplineName,
  userProfile,
  onClose,
}) => {
  const [InfoDialogContent, setInfoDialogContent] = useState(null);

  useEffect(() => {
    function loadInfoDialogContent() {
      import(`./${process.env.REACT_APP_API_REFERENCE}/${type}`).then(
        (module) => {
          setInfoDialogContent(module.Content);
        }
      );
    }

    if (InfoDialogContent === null) {
      loadInfoDialogContent();
    }

    // return () => {
    //   setInfoDialogContent(undefined);
    // };
  }, [InfoDialogContent, type]);

  return (
    <Dialog open={dlgOpen} onClose={() => onClose()} maxWidth={"md"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {InfoDialogContent && (
          <InfoDialogContent
            disciplineName={disciplineName}
            userProfile={userProfile}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
};
