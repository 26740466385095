import axiosInstance from "../actions/axios";
import { emailAddress, publicName, siteUrl } from "./config";
import { getEmailAdmin } from "./utils";

export async function checkNicknameExists(nickname) {
  const reply = await axiosInstance
    .post("/users/checkuser.php", { nickname })
    .then((response) => {
      if (response === undefined) {
        return true;
      } else {
        const { reply } = response.data;

        return reply;
      }
    });

  return reply !== "false";
}

export async function registerUser(user, discipline) {
  const url = siteUrl + "/confirmmail";
  const reply = await axiosInstance
    .post("/users/register.php", {
      ...user,
      from: publicName,
      emailadmin: getEmailAdmin(discipline),
      url,
    })
    .then((response) => {
      if (response === undefined) {
        return { error: true, message: "Errore durante registrazione" };
      } else {
        if (response.data.error !== "false") {
          return { error: true, message: "Errore durante registrazione" };
        } else {
          const reply = {
            ...response.data,
            error: false,
            message: response.data?.message || "Utente registrato",
          };

          return reply;
        }
      }
    });

  return reply;
}

export async function sendTokenMail(email) {
  const url = siteUrl + "/lostpassword";
  const reply = await axiosInstance
    .post("/users/lostpassword.php", {
      email,
      from: publicName,
      emailfrom: emailAddress,
      url,
    })
    .then((response) => {
      if (response === undefined) {
        return { error: true, message: "Errore durante invio token" };
      } else {
        if (response.data?.error) {
          return { error: true, message: "Mail non trovata" };
        } else {
          return { error: false, message: response.data.message };
        }
      }
    });

  return reply;
}

export async function verifyMail(mailtoken, sendnew, discName) {
  const url = siteUrl + "/confirmmail";
  const reply = await axiosInstance
    .post("/users/verifymail.php", {
      mailtoken,
      sendnew,
      sendmail: process.env.REACT_APP_SENDMAILAFTERCONFIRM || false,
      discName,
      from: publicName,
      emailadmin: emailAddress,
      url,
      siteUrl,
    })
    .then((response) => {
      if (response === undefined) {
        return { error: true, message: "Errore durante verifica" };
      } else {
        if (response.data?.ret !== true) {
          return { error: true, message: "Mail non verificata" };
        } else {
          return sendnew
            ? { error: false, message: "Nuova mail inviata" }
            : {
                error: false,
                message: "Mail verificata",
                nickname: response.data?.nickname,
                tmpID: response.data?.tmpID,
              };
        }
      }
    });

  return reply;
}

export function isParentOf(uInfo, uProfile) {
  return uProfile?.parents?.findIndex((parent) => parent.id === uInfo.id) >= 0;
}
