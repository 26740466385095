import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { loadParams, updateDisc } from "../actions/paramActions";
import { Add, Delete, Edit } from "@mui/icons-material";
import { makeStyles } from '@mui/styles';
import { unloadProfile } from "../actions/userActions";

const useStyles = makeStyles({
  deftable: {
    "& td": { fontSize: "0.9rem", paddingTop: 0, paddingBottom: 0 },
  },
});

export default function DisciplinesScreen() {
  const paramList = useSelector((state) => state.paramList);
  const { disciplines, loading, error } = paramList;

  const [editDisc, setEditDisc] = useState(null);
  const [dlgOpen, setDlgOpen] = useState(false);

  const dispatch = useDispatch();

  const classes = useStyles();

  function tableProps() {
    return {
      className: classes.deftable,
    };
  }

  function handleDiscSave(action, disc) {
    dispatch(updateDisc(action, disc));
  }

  function renderDiscActions(value, { rowData }, updateValue) {
    const disc = disciplines.find((d) => d.id === rowData[0]);

    return (
      <Stack direction="row" spacing={0.4}>
        <IconButton
          size="small"
          onClick={() => {
            setEditDisc(disc);
            setDlgOpen(true);
          }}
        >
          <Edit />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            handleDiscSave("remove", disc);
          }}
        >
          <Delete />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            setEditDisc({
              id: "",
              name: "",
              prefix: "",
              email: "",
            });
            setDlgOpen(true);
          }}
        >
          <Add />
        </IconButton>
      </Stack>
    );
  }

  const discColumns = [
    {
      name: "id",
      label: "Chiave",
    },
    { name: "name", label: "Descrizione" },
    { name: "prefix", label: "Prefisso categorie" },
    { name: "email", label: "e-Mail" },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderDiscActions },
    },
  ];

  useEffect(() => {
    dispatch(unloadProfile());
    if (!disciplines?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, disciplines]);

  const EditDiscDialog = ({ dialogDisc }) => {
    const [disc, setDisc] = useState(dialogDisc);

    const handleDlgClose = (save) => {
      if (save) {
        //TODO: check univocita' id e prefix
        handleDiscSave(dialogDisc?.id ? "edit" : "add", disc);
      }

      setDlgOpen(false);
    };

    return (
      <Dialog open={dlgOpen} onClose={() => handleDlgClose(false)}>
        <DialogTitle>
          {dialogDisc?.id !== "" ? "Modifica " + disc?.name : "Aggiungi"}
        </DialogTitle>
        <DialogContent>
          {!dialogDisc?.id !== "" && (
            <TextField
              margin="dense"
              id="id"
              label="Chiave"
              type="text"
              variant="standard"
              fullWidth
              value={disc?.id}
              disabled={dialogDisc?.id !== ""}
              onChange={(event) => setDisc({ ...disc, id: event.target.value })}
            />
          )}
          <TextField
            margin="dense"
            id="name"
            label="Descrizione"
            type="text"
            variant="standard"
            fullWidth
            value={disc?.name}
            onChange={(event) => setDisc({ ...disc, name: event.target.value })}
          />
          <TextField
            margin="dense"
            id="prefix"
            label="Prefisso"
            type="text"
            variant="standard"
            fullWidth
            value={disc?.prefix}
            disabled={dialogDisc?.id !== ""}
            onChange={(event) =>
              setDisc({ ...disc, prefix: event.target.value })
            }
          />
          <TextField
            margin="dense"
            id="prefix"
            label="e-Mail"
            type="email"
            variant="standard"
            fullWidth
            value={disc?.email}
            onChange={(event) =>
              setDisc({ ...disc, email: event.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDlgClose(false)}>Annulla</Button>
          <Button onClick={() => handleDlgClose(true)}>Salva</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      <>
        <EditDiscDialog dialogDisc={editDisc} />
        {disciplines?.length > 0 && (
          <Box margin={2} flex={true} flexGrow="1">
            <MUIDataTable
              title="Sport"
              data={disciplines}
              columns={discColumns}
              options={{
                selectableRowsHideCheckboxes: true,
                viewColumns: false,
                filter: false,
                rowsPerPage: 15,
                setTableProps: tableProps,
              }}
            />
          </Box>
        )}
      </>
    </>
  );
}
