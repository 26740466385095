import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  LinearProgress,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { loadParams, updateParam } from "../actions/paramActions";
import { Add, Delete, Edit } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { unloadProfile } from "../actions/userActions";

const useStyles = makeStyles({
  deftable: {
    "& td": { fontSize: "0.9rem", paddingTop: 0, paddingBottom: 0 },
  },
});

export default function ParametersScreen() {
  const paramList = useSelector((state) => state.paramList);
  const { parameters, loading, error } = paramList;

  const [orderedparameters, setOrderedparameters] = useState([]);
  const [editParam, setEditParam] = useState(null);
  const [dlgOpen, setDlgOpen] = useState(false);

  const [showReserved, setShowReserved] = useState(false);

  const dispatch = useDispatch();

  const classes = useStyles();

  function tableProps() {
    return {
      className: classes.deftable,
    };
  }

  function handleParamSave(action, param) {
    dispatch(updateParam(action, param));
  }

  function renderParamActions(value, { rowData }, updateValue) {
    const param = orderedparameters.find((p) => p.name === rowData[0]);

    return (
      <Stack direction="row" spacing={0.4}>
        <IconButton
          size="small"
          onClick={() => {
            setEditParam(param);
            setDlgOpen(true);
          }}
        >
          <Edit />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            handleParamSave("delete", param);
          }}
        >
          <Delete />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            setEditParam({
              name: "",
              description: "",
              value: "",
              order: parseInt(param.order),
            });
            setDlgOpen(true);
          }}
        >
          <Add />
        </IconButton>
      </Stack>
    );
  }

  const paramColumns = [
    {
      name: "name",
      label: "Chiave",
    },
    { name: "description", label: "Etichetta" },
    { name: "value", label: "Valore" },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderParamActions },
    },
  ];

  useEffect(() => {
    dispatch(unloadProfile());
    if (!parameters?.length) {
      dispatch(loadParams());
    } else {
      const pars = parameters.sort(
        (a, b) => parseInt(a.order) - parseInt(b.order)
      );

      setOrderedparameters(pars);
    }
  }, [dispatch, parameters]);

  const EditParamDialog = ({ dialogParam }) => {
    const [param, setParam] = useState(dialogParam);

    const handleDlgClose = (save) => {
      if (save) {
        handleParamSave("set", param);
      }

      setDlgOpen(false);
    };

    return (
      <Dialog open={dlgOpen} onClose={() => handleDlgClose(false)}>
        <DialogTitle>
          {param?.id > 0 ? "Modifica " + param?.name : "Aggiungi"}
        </DialogTitle>
        <DialogContent>
          {!param?.id && (
            <TextField
              margin="dense"
              id="name"
              label="Chiave"
              type="text"
              variant="standard"
              fullWidth
              value={param?.name}
              disabled={param?.id > 0}
              onChange={(event) =>
                setParam({ ...param, name: event.target.value })
              }
            />
          )}
          <TextField
            margin="dense"
            id="description"
            label="Descrizione"
            type="text"
            variant="standard"
            fullWidth
            value={param?.description}
            onChange={(event) =>
              setParam({ ...param, description: event.target.value })
            }
          />
          <TextField
            margin="dense"
            id="value"
            label="Valore"
            type="text"
            variant="standard"
            fullWidth
            value={param?.value}
            onChange={(event) =>
              setParam({ ...param, value: event.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDlgClose(false)}>Annulla</Button>
          <Button onClick={() => handleDlgClose(true)}>Salva</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      <>
        <EditParamDialog dialogParam={editParam} />
        <FormLabel id="enable">Mostra parametri riservati</FormLabel>
        <Switch
          name="enable"
          value={showReserved}
          onClick={() => setShowReserved(!showReserved)}
        />
        {orderedparameters?.length > 0 && (
          <Box margin={2} flex={true} flexGrow="1">
            <MUIDataTable
              title="Parametri"
              data={orderedparameters.filter(
                (p) => p.reserved === false || showReserved
              )}
              columns={paramColumns}
              options={{
                selectableRowsHideCheckboxes: true,
                viewColumns: false,
                filter: false,
                rowsPerPage: 100,
                setTableProps: tableProps,
              }}
            />
          </Box>
        )}
      </>
    </>
  );
}
