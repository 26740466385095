import axios from "axios";
import {
  CATEGORIES_LOAD_FAIL,
  CATEGORIES_LOAD_REQUEST,
  CATEGORIES_LOAD_SUCCESS,
  CATEGORIES_SAVE_FAIL,
  CATEGORIES_SAVE_REQUEST,
  CATEGORIES_SAVE_SUCCESS,
  PARAMS_LOAD_FAIL,
  PARAMS_LOAD_REQUEST,
  PARAMS_LOAD_SUCCESS,
  PARAMS_SAVE_FAIL,
  PARAMS_SAVE_REQUEST,
  PARAMS_SAVE_SUCCESS,
  PARAMS_SET_DISCIPLINE,
} from "../constants/paramConstants";
import { apiReference, baseApiUrl, publicName } from "../utils/config";
import axiosInstance, { manageReason } from "./axios";
import { signout } from "./userActions";

export const setDiscipline = (disc) => async (dispatch, getState) => {
  const { discipline } = getState();
  if (discipline !== disc) {
    dispatch({ type: PARAMS_SET_DISCIPLINE, payload: disc });
    // force reload of locations, groups, etc.
    //TODO: evitare ricaricamento dei dati ma solo processare parametri
    dispatch(loadParams());
  }
};

export const loadParams = () => async (dispatch) => {
  dispatch({ type: PARAMS_LOAD_REQUEST, payload: {} });
  axiosInstance
    .post("/params.php", { request: "get" })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({ type: PARAMS_LOAD_SUCCESS, payload: response.data });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: PARAMS_LOAD_FAIL,
          payload: message,
        });
      }
    });
};

export const updateParam = (request, param) => async (dispatch) => {
  dispatch({ type: PARAMS_SAVE_REQUEST, payload: { request, param } });
  axiosInstance
    .post("/params.php", { request, ...param })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({ type: PARAMS_SAVE_SUCCESS, payload: response.data });
      }
    })
    .catch((reason) => {
      console.error("fail",reason);
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: PARAMS_SAVE_FAIL,
          payload: message,
        });
      }
    });
};

export const updateLocations = (type, loc) => (dispatch, getState) => {
  const state = getState().paramList;
  const paramName = "LOCATIONS_" + state.discipline.toUpperCase();
  const indexl = state.parameters.findIndex((p) => p.name === paramName);
  if (indexl >= 0) {
    const param = state.parameters[indexl];
    let newLocs = [...state.locations];
    switch (type) {
      case "add":
        newLocs.push(loc);
        break;
      case "edit":
        newLocs = newLocs.map((l) => {
          return l.id === loc.id ? loc : l;
        });
        break;
      case "remove":
        newLocs = newLocs.filter((l) => l.id !== loc.id);
        break;
      default:
        break;
    }

    const newParamValue = newLocs
      .map((loc) => `${loc.id}:${loc.name}`)
      .join(",");
    dispatch(updateParam("set", { ...param, value: newParamValue }));
  }
};

export const updateGroups = (type, key, group) => (dispatch, getState) => {
  const state = getState().paramList;
  const paramName = "GROUPS_" + state.discipline.toUpperCase();
  const indexg = state.parameters.findIndex((p) => p.name === paramName);
  if (indexg >= 0) {
    const param = state.parameters[indexg];
    let newGroups = Object.assign({}, state.groupNames);
    switch (type) {
      case "add":
      case "edit":
        newGroups[key] = group;
        break;
      case "remove":
        delete newGroups[key];
        break;
      default:
        break;
    }

    const newParamValue = Object.entries(newGroups)
      .map(([key, value]) => `${key}:${value.name}:${value.location}`)
      .join(",");
    dispatch(updateParam("set", { ...param, value: newParamValue }));
  }
};

export const updateDocTypes = (type, doc) => (dispatch, getState) => {
  const state = getState().paramList;
  const paramName = "DOCTYPES_" + state.discipline.toUpperCase();
  const indexdt = state.parameters.findIndex((p) => p.name === paramName);
  if (indexdt >= 0) {
    const param = state.parameters[indexdt];
    let newDocTypes = [...state.docTypes];
    switch (type) {
      case "add":
        newDocTypes.push({ ...doc, edit: true });
        break;
      case "edit":
        newDocTypes = newDocTypes.map((dt) => {
          return dt.key === doc.key ? doc : dt;
        });
        break;
      case "remove":
        newDocTypes = newDocTypes.filter((dt) => dt.key !== doc.key);
        break;
      default:
        break;
    }

    const newParamValue = newDocTypes
      .filter((dt) => dt?.edit === true)
      .map((dt) => `${dt.key}:${dt.label}:${dt.filename}:${dt.info}`)
      .join(",");
    dispatch(updateParam("set", { ...param, value: newParamValue }));
  }
};

export const updateLocPrefs = (type, lp) => (dispatch, getState) => {
  const state = getState().paramList;
  const paramName = "LOCPREFS_" + state.discipline.toUpperCase();
  const indexlp = state.parameters.findIndex((p) => p.name === paramName);
  if (indexlp >= 0) {
    const param = state.parameters[indexlp];
    let newLocPrefs = [...state.locPrefs];
    let newLocPrefsEnabled = state.locPrefsEnabled;

    switch (type) {
      case "add":
        newLocPrefs.push(lp);
        break;
      case "edit":
        newLocPrefs = newLocPrefs.map((l) => {
          return l.id === lp.id ? lp : l;
        });
        break;
      case "remove":
        newLocPrefs = newLocPrefs.filter((l) => l.id !== lp.id);
        break;
      case "enable":
        newLocPrefsEnabled = true;
        break;
      case "disable":
        newLocPrefsEnabled = false;
        break;
      default:
        break;
    }

    const prefix = newLocPrefsEnabled ? "" : "D,";
    const newParamValue =
      prefix + newLocPrefs.map((l) => `${l.id}$${l.name}`).join(",");
    dispatch(updateParam("set", { ...param, value: newParamValue }));
  }
};

const atrigger = (mode, params) => {
  const atriggerUrl = "http://api.atrigger.com/v1/tasks/" + mode;

  const key = process.env.REACT_APP_ATRIGGER_KEY || "";
  const secret = process.env.REACT_APP_ATRIGGER_SECRET || "";

  let atriggerParams = {
    key,
    secret,
    tag_disc: params.discipline,
  };

  if (mode === "create") {
    const myParams = {
      fromAtrigger: 1,
      reference: apiReference,
      ...params,
    };

    const myUriParams =
      "?" +
      Object.entries(myParams)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

    const myUrl = baseApiUrl + "users/trigger.php" + myUriParams;

    atriggerParams["url"] = myUrl;
    atriggerParams["timeSlice"] = "1day";
    atriggerParams["count"] = -1;
  }

  //TODO: check ok / errore
  axios.get(atriggerUrl, { params: atriggerParams });
};

export const updateDisc = (type, disc) => (dispatch, getState) => {
  const state = getState().paramList;
  const paramName = "DISCIPLINES";
  const indexd = state.parameters.findIndex((p) => p.name === paramName);
  if (indexd >= 0) {
    const param = state.parameters[indexd];
    let newDisciplines = [];
    switch (type) {
      case "add":
        atrigger("create", {
          from: publicName,
          discipline: disc.id,
          emailadmin: disc.email,
        });

        const paramLoc = {
          name: "LOCATIONS_" + disc.id.toUpperCase(),
          description: "Campi di allenamento",
          value: "",
          order: 101,
          reserved: true,
        };
        dispatch(updateParam("set", paramLoc));
        const paramGr = {
          name: "GROUPS_" + disc.id.toUpperCase(),
          description: "Gruppi per ciascun campo",
          value: "",
          order: 102,
          reserved: true,
        };
        dispatch(updateParam("set", paramGr));
        const paramDocTypes = {
          name: "DOCTYPES_" + disc.id.toUpperCase(),
          description: "Tipi di documento",
          value: "",
          order: 103,
          reserved: true,
        };
        dispatch(updateParam("set", paramDocTypes));
        const paramLocPref = {
          name: "LOCPREFS_" + disc.id.toUpperCase(),
          description: "Preferenze Prova",
          value: "",
          order: 104,
          reserved: true,
        };
        dispatch(updateParam("set", paramLocPref));

        newDisciplines = [...state.disciplines, disc];
        break;
      case "edit":
        newDisciplines = state.disciplines.map((d) => {
          return d.id === disc.id ? disc : d;
        });
        break;
      case "remove":
        atrigger("delete", {
          discipline: disc.id,
        });

        const paramLocD = state.parameters.find(
          (p) => p.name === "LOCATIONS_" + disc.id.toUpperCase()
        );
        dispatch(updateParam("delete", paramLocD));
        const paramGrD = state.parameters.find(
          (p) => p.name === "GROUPS_" + disc.id.toUpperCase()
        );
        dispatch(updateParam("delete", paramGrD));
        const paramLocPrefsD = state.parameters.find(
          (p) => p.name === "LOCPREFS_" + disc.id.toUpperCase()
        );
        dispatch(updateParam("delete", paramLocPrefsD));
        const paramDocTypesD = state.parameters.find(
          (p) => p.name === "DOCTYPES_" + disc.id.toUpperCase()
        );
        dispatch(updateParam("delete", paramDocTypesD));

        newDisciplines = [...state.disciplines];
        const idx = newDisciplines.findIndex((d) => d.id === disc.id);
        newDisciplines.splice(idx, 1);
        break;
      default:
        break;
    }

    const newParamValue = newDisciplines
      .map((disc) => `${disc.id}:${disc.name}:${disc.prefix}:${disc.email}`)
      .join(",");
    dispatch(updateParam("set", { ...param, value: newParamValue }));
  }
};

export const loadCategories = () => async (dispatch) => {
  dispatch({ type: CATEGORIES_LOAD_REQUEST, payload: {} });
  axiosInstance
    .post("/users/categories.php", { request: "get" })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({ type: CATEGORIES_LOAD_SUCCESS, payload: response.data });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: CATEGORIES_LOAD_FAIL,
          payload: message,
        });
      }
    });
};

export const updateCategory = (request, cat) => async (dispatch) => {
  dispatch({ type: CATEGORIES_SAVE_REQUEST, payload: { request, cat } });
  axiosInstance
    .post("/users/categories.php", { request, ...cat })
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({ type: CATEGORIES_SAVE_SUCCESS, payload: response.data });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: CATEGORIES_SAVE_FAIL,
          payload: message,
        });
      }
    });
};
