import packageInfo from "../../package.json";

export const baseApiUrl = process.env.REACT_APP_API_URL;
export const apiReference = process.env.REACT_APP_API_REFERENCE;
export const baseTransferApiUrl = process.env.REACT_APP_API_TRANSFER_URL;
export const transferApiReference =
  process.env.REACT_APP_API_TRANSFER_REFERENCE;
export const baseFileUrl = process.env.REACT_APP_FILE_URL;
export const baseTransferFileUrl = process.env.REACT_APP_FILE_TRANSFER_URL;

export const publicName = process.env.REACT_APP_PUBLIC_NAME;
export const siteName = process.env.REACT_APP_SITE;
export const selfCertC19 = process.env.REACT_APP_SELFCERTC19;
export const siteUrl = process.env.REACT_APP_SITE_URL;
export const emailAddress = process.env.REACT_APP_EMAIL;

export const baseInfoDialogs = JSON.parse(process.env.REACT_APP_INFODIALOGS);

export const transferDocs = JSON.parse(process.env.REACT_APP_API_TRANSFER_DOCS);
export const canRegisterTrial = JSON.parse(
  process.env.REACT_APP_API_REGISTER_TRIAL
);
export const canManageAttendees = JSON.parse(
  process.env.REACT_APP_API_MANAGE_ATTENDEES
);
export const canManageEvents = JSON.parse(
  process.env.REACT_APP_API_MANAGE_EVENTS
);

export const defaultCurrentFilter = JSON.parse(
  process.env.REACT_APP_DEFAULT_CURRENT_FILTER
);
export const hasTrainingDays = JSON.parse(
  process.env.REACT_APP_HAS_TRAINING_DAYS
);
export const hasTShirt = JSON.parse(process.env.REACT_APP_HAS_TSHIRT);
export const signupDocTypes = JSON.parse(process.env.REACT_APP_SIGDOCTYPES);
export const baseDocTypes = JSON.parse(process.env.REACT_APP_DOCTYPES);
export const canTransfer = JSON.parse(process.env.REACT_APP_CAN_TRANSFER);

export const appVersion = packageInfo.version;
