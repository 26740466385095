import { Alert, Button, LinearProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { loadProfile } from "../actions/userActions";
import {
  loadCategories,
  loadParams,
  setDiscipline,
} from "../actions/paramActions";

export default function SignupModule() {
  const [curId, setCurId] = useState(0);

  const [discName, setDiscName] = useState("Sport");

  const [PrintModule, setPrintModule] = useState(null);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  let params = useParams();

  const userDetails = useSelector((state) => state.userDetails);
  const { userProfile, loading, error, message } = userDetails;

  const paramList = useSelector((state) => state.paramList);
  const { categories, disciplines, groupNames } = paramList;

  const dispatch = useDispatch();

  useEffect(() => {
    async function loadPrintModule() {
      setPrintModule(
        (
          await import(
            `./${process.env.REACT_APP_API_REFERENCE}/SignupPrintModule`
          )
        ).SignupPrintModule
      );
    }

    if (PrintModule === null) {
      loadPrintModule();
    }
  }, [PrintModule]);

  useEffect(() => {
    let newId =
      params && params.id
        ? params.id
        : userInfo.isParent
        ? userInfo.childid
        : userInfo.id;
    setCurId(newId);
  }, [userInfo, userProfile, params]);

  useEffect(() => {
    if (curId !== 0 && (!userProfile || userProfile.id !== curId)) {
      dispatch(loadProfile(curId));
    }
  }, [userInfo, userProfile, dispatch, curId]);

  useEffect(() => {
    if (!categories?.length) {
      dispatch(loadCategories());
    }
  }, [dispatch, categories]);

  useEffect(() => {
    if (!disciplines?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, disciplines]);

  useEffect(() => {
    if (
      userProfile?.category !== undefined &&
      categories?.length > 0 &&
      discName === "Sport"
    ) {
      const cat = categories.find((cat) => cat.key === userProfile.category);
      if (cat?.discipline && disciplines?.length > 0) {
        const index = disciplines.findIndex(
          (disc) => disc.id === cat.discipline
        );
        if (index >= 0) setDiscName(disciplines[index].name);
        dispatch(setDiscipline(cat.discipline));
      }
    }
  }, [categories, userProfile, disciplines, discName, dispatch]);

  const marginTop = "10px";
  const marginRight = "5px";
  const marginBottom = "10px";
  const marginLeft = "5px";
  const getPageMargins = () => {
    return `
    @page
      { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }

      .pageSignup {
        margin-top: 20rem;
      }
    `;
  };

  const moduleRef = useRef();

  return (
    <div>
      {loading && <LinearProgress />}
      {message && <Alert severity="info">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      {userProfile && process.env.REACT_APP_API_REFERENCE && (
        <>
          <ReactToPrint
            pageStyle={getPageMargins()}
            trigger={() => (
              <Button sx={{ alignSelf: "center", mt: 4, mb: 4 }}>Stampa</Button>
            )}
            content={() => moduleRef.current}
            documentTitle={`Modulo Iscrizione ${discName} - ${userProfile.surname} ${userProfile.name}`}
          />
          {PrintModule && (
            <PrintModule
              user={userProfile}
              loggedUser={userInfo}
              discName={discName}
              groupName={
                groupNames?.length > 0 &&
                userProfile?.group !== undefined &&
                groupNames.includes(userProfile.group)
                  ? groupNames[userProfile.group].name
                  : ""
              }
              ref={moduleRef}
            />
          )}
        </>
      )}
    </div>
  );
}
