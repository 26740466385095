import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  loadProfile,
  setlocked,
  setNewPassword,
  setparentflag,
  setUserAcl,
  signout,
  updatedoc,
  updateprofile,
} from "../actions/userActions";
import { getAllowedCategories, localizeYesNo } from "../utils/utils";
import MUIDataTable from "mui-datatables";
import { Avatar, IconButton, Link, Stack, Tooltip } from "@mui/material";
import ModifyButtons from "../components/ModifyButtons";
import {
  baseFileUrl,
  baseInfoDialogs,
  hasTrainingDays,
  hasTShirt,
  selfCertC19,
} from "../utils/config";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import {
  loadCategories,
  loadParams,
  setDiscipline,
} from "../actions/paramActions";
import { AclDialog } from "../components/AclDialog";
import { ManageAccounts } from "@mui/icons-material";
import { InfoDialog } from "../components/InfoDialog";
import { downloadDoc } from "../utils/docs";
import { ChangePasswordDialog } from "../components/ChangePasswordDialog";

export default function ProfileScreen() {
  const [modUser, setModUser] = useState(null);

  const [categoryNames, setCategoryNames] = useState([]);
  const [categoryDisciplines, setCategoryDisciplines] = useState([]);

  const [discName, setDiscName] = useState("Sport");

  const [pwdDialogOpen, setPwdDialogOpen] = useState(false);
  const [aclDialogOpen, setAclDialogOpen] = useState(false);

  const [infodialogsmap, setInfodialogsmap] = useState([]);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  let params = useParams();

  const userDetails = useSelector((state) => state.userDetails);
  const { userProfile, loading, error, message, authError } = userDetails;

  const paramList = useSelector((state) => state.paramList);
  const {
    categories,
    parameters,
    disciplines,
    locations,
    groupNames,
    docTypes,
  } = paramList;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleAddParent() {
    dispatch(
      setparentflag(userProfile.id, "add", {
        surname: userProfile.surname,
        phonenr: userProfile.phonenr,
        email: userProfile.email,
      })
    );
  }

  function handleParentEdit(type, par_id) {
    dispatch(setparentflag(userProfile.id, type, { parentId: par_id }));
  }

  async function handleDownload(profile, type, doc) {
    const ret = await downloadDoc(
      profile,
      type,
      doc.id,
      doc.description,
      doc.when2,
      doc.ext
    );

    if (ret?.error) {
      console.log("cannot download document", ret.error);
      if (ret.exit) dispatch(signout());
    }
  }

  function toBool(value, { rowIndex, columnIndex }, updateValue) {
    const text = localizeYesNo(value > 0);

    return (
      <div align="center">
        <Button
          disabled={userProfile?.locked}
          onClick={() =>
            handleParentEdit(
              columnIndex === 2 ? "receipt" : "maincontact",
              userProfile.parents[rowIndex].id
            )
          }
        >
          {text}
        </Button>
      </div>
    );
  }

  function renderActions(value, { rowIndex, columnIndex }, updateValue) {
    return userProfile?.locked ? (
      <div align="center">
        <IconButton
          onClick={() =>
            navigate(
              `/profile/${userProfile.id}/parents/${userProfile.parents[rowIndex].id}`
            )
          }
        >
          <VisibilityIcon />
        </IconButton>
      </div>
    ) : (
      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() =>
            navigate(
              `/profile/${userProfile.id}/parents/${userProfile.parents[rowIndex].id}`
            )
          }
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            handleParentEdit("delete", userProfile.parents[rowIndex].id)
          }
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    );
  }

  const parentColumns = [
    { name: "surname", label: "Cognome" },
    { name: "name", label: "Nome" },
    {
      name: "receipt",
      label: "Intestatario ricevuta",
      options: { customBodyRender: toBool },
    },
    {
      name: "maincontact",
      label: "Contatto preferenziale",
      options: { customBodyRender: toBool },
    },
    { name: "phonenr", label: "Telefono" },
    { name: "email", label: "e-Mail" },
    { name: "cf", label: "Codice Fiscale" },
    { name: "link", label: "Relazione" },
    { name: "", label: "Azioni", options: { customBodyRender: renderActions } },
  ];

  const restoreUserProfileState = useCallback(() => {
    setModUser({
      ...userProfile,
      birthdateD: moment(userProfile.birthdate, "DD/MM/YYYY").toDate(),
      signupdateD:
        userProfile.signupdate !== ""
          ? moment(userProfile.signupdate, "DD/MM/YYYY").toDate()
          : "",
      certexpireD:
        userProfile.certexpire !== "NA"
          ? moment(userProfile.certexpire, "DD/MM/YYYY").toDate()
          : "",
      uispexpireD:
        userProfile.uispexpire !== "NA"
          ? moment(userProfile.uispexpire, "DD/MM/YYYY").toDate()
          : "",
      fidalexpireD:
        userProfile.fidalexpire !== "NA"
          ? moment(userProfile.fidalexpire, "DD/MM/YYYY").toDate()
          : "",
    });
  }, [userProfile]);

  useEffect(() => {
    if (!categories?.length) {
      dispatch(loadCategories());
    } else {
      let names = [];
      categories.forEach((cat) => (names[cat.key] = cat.description));
      setCategoryNames(names);
      let catDisciplines = [];
      categories.forEach((cat) => (catDisciplines[cat.key] = cat.discipline));
      setCategoryDisciplines(catDisciplines);
    }
  }, [dispatch, categories]);

  useEffect(() => {
    if (!parameters?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, parameters]);

  useEffect(() => {
    if (authError) {
      navigate("/");

      return;
    }

    if (userProfile?.id !== params.id) {
      setDiscName("Sport");
      dispatch(loadProfile(params.id));
    }

    if (userProfile && userProfile.id === params.id) {
      restoreUserProfileState();
    }
  }, [
    userInfo,
    userProfile,
    error,
    authError,
    navigate,
    params,
    dispatch,
    restoreUserProfileState,
  ]);

  useEffect(() => {
    if (
      userProfile?.category !== undefined &&
      categories?.length > 0 &&
      userProfile?.id === params.id &&
      discName === "Sport"
    ) {
      const cat = categories.find((cat) => cat.key === userProfile.category);
      if (cat?.discipline && disciplines?.length > 0) {
        const index = disciplines.findIndex(
          (disc) => disc.id === cat.discipline
        );
        if (index >= 0) setDiscName(disciplines[index].name);
        dispatch(setDiscipline(cat.discipline));
      }
    }
  }, [categories, userProfile, disciplines, discName, params.id, dispatch]);

  useEffect(() => {
    if (infodialogsmap.length === 0 && baseInfoDialogs.length > 0) {
      let idm = baseInfoDialogs.map((bid) => {
        return {
          ...bid,
          open: false,
        };
      });

      setInfodialogsmap(idm);
    }
  }, [infodialogsmap]);

  function handleInfoDialog(idx, value) {
    const idm = infodialogsmap.map((id, index) => {
      if (index === idx) {
        return {
          ...id,
          open: value,
        };
      }

      return {
        ...id,
      };
    });

    setInfodialogsmap(idm);
  }

  const onChangePassword = (save, password) => {
    if (save) dispatch(setNewPassword(userProfile.id, password));
    setPwdDialogOpen(false);
  };

  const onEditUser = (event) => {
    event.preventDefault();

    if (!userProfile?.locked) {
      // restore data
      restoreUserProfileState();
    }

    dispatch(setlocked(userProfile.id, userProfile?.locked));
  };

  const onUnlockProfile = () => {
    dispatch(setlocked(userProfile.id, false));
  };

  const onUpdateProfile = (event) => {
    const valid = event.currentTarget.form.reportValidity();
    if (!valid) return;

    const newProfile = {
      userId: userProfile.id,
      ...modUser,
      birthdateD: undefined,
      signupdateD: undefined,
      certexpireD: undefined,
      uispexpireD: undefined,
      fidalexpireD: undefined,
      birthdate: moment(modUser.birthdateD).format("DD/MM/YYYY"),
      signupdate:
        modUser.signupdateD !== ""
          ? moment(modUser.signupdateD).format("DD/MM/YYYY")
          : "",
      certexpire:
        modUser.certexpireD !== ""
          ? moment(modUser.certexpireD).format("DD/MM/YYYY")
          : "",
      uispexpire:
        modUser.uispexpireD !== ""
          ? moment(modUser.uispexpireD).format("DD/MM/YYYY")
          : "",
      fidalexpire:
        modUser.fidalexpireD !== ""
          ? moment(modUser.fidalexpireD).format("DD/MM/YYYY")
          : "",
    };

    dispatch(updateprofile(newProfile));
  };

  const trainingsError = () => {
    if (modUser.trainings === 0x0f) return "Troppi giorni selezionati";
    if (modUser.trainings === 0x00) return "Selezionare almeno un giorno";
    return null;
  };

  const handleChangeTraining = (event) => {
    const num = parseInt(event.target.name);
    let trainings = modUser.trainings;
    if (event.target.checked) {
      trainings = trainings | num;
    } else {
      trainings = trainings & ~num;
    }
    setModUser({ ...modUser, trainings });
  };

  const onChangeUserParam = (param, value, type = "text") => {
    var val = value;
    if (type === "checkbox") {
      val = value ? 1 : 0;
    }
    setModUser({ ...modUser, [param]: val });
  };

  const allowedCategories = () => {
    const catopts = getAllowedCategories(userInfo, userProfile);

    var cats = [];
    catopts.forEach((opt) => {
      if (!opt.startsWith("mail_") && !cats.includes(opt)) cats.push(opt);
    });

    return cats.map((opt) => (
      <MenuItem key={opt} value={opt}>
        {categoryNames[opt]}
      </MenuItem>
    ));
  };

  function onAclDialogClose(save, user) {
    if (save) {
      dispatch(setUserAcl(user.id, user.acl));
    }

    setAclDialogOpen(false);
  }

  function onValidateDocument(docId) {
    dispatch(updatedoc(userProfile.id, "validatedoc", { docId }));
  }

  function onValidateCertificate() {
    dispatch(
      updatedoc(userProfile.id, "validatedoc", {
        docType: "certificate",
        docId: 0,
      })
    );
    setModUser({ ...modUser, certvalidated: true });
  }

  return (
    <>
      {loading && <LinearProgress />}
      {message && !userProfile?.isinternal && (
        <Alert severity="info">{message}</Alert>
      )}
      {error && !userProfile?.isinternal && (
        <Alert severity="error">{error}</Alert>
      )}
      {userProfile &&
        !userProfile?.isinternal &&
        userProfile?.uisperr !== "" && (
          <Alert severity="error">{userProfile.uisperr}</Alert>
        )}
      {userProfile &&
        !userProfile?.isinternal &&
        userProfile?.fidalerr !== "" && (
          <Alert severity="error">{userProfile.fidalerr}</Alert>
        )}
      {userProfile &&
        !userProfile?.isinternal &&
        userProfile?.certerr !== "" && (
          <Alert
            severity={userProfile.certwarning ? "warning" : "error"}
            action={
              userProfile.certfilename !== "" && (
                <>
                  <IconButton
                    onClick={() =>
                      handleDownload(userProfile, "compcert", {
                        id: 1,
                        description: "Certificato Medico",
                        when2: "",
                        ext: userProfile.certfilename.split(".").pop(),
                      })
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                  {!modUser?.certvalidated &&
                    modUser?.certexpire !== "" &&
                    (userInfo?.isAdmin || modUser?.canManage) && (
                      <IconButton onClick={() => onValidateCertificate()}>
                        <UnpublishedIcon />
                      </IconButton>
                    )}
                </>
              )
            }
          >
            {userProfile.certerr}
          </Alert>
        )}
      {
        //TODO: messaggio errore tesseramento
      }
      <ChangePasswordDialog
        dlgOpen={pwdDialogOpen}
        pwdUser={modUser}
        onConfirm={onChangePassword}
      />
      <AclDialog
        dlgOpen={aclDialogOpen}
        aclUser={userProfile}
        onConfirm={onAclDialogClose}
      />
      {userProfile &&
        discName &&
        !userInfo?.hasAcl &&
        infodialogsmap?.length > 0 && (
          <>
            {infodialogsmap.map((id, idx) => (
              <InfoDialog
                key={id.key}
                dlgOpen={id.open}
                title={id.title}
                type={id.key}
                disciplineName={discName}
                userProfile={userProfile}
                onClose={() => handleInfoDialog(idx, false)}
              />
            ))}
          </>
        )}
      {userProfile &&
        !userProfile?.isinternal &&
        (userProfile.isjr || userProfile.iscomp) &&
        docTypes?.length > 0 &&
        docTypes
          ?.filter((dt) => !dt?.info)
          .map((dt) => {
            const doc = userProfile.documents.find((d) => d.subtype === dt.key);

            return doc && doc.canValidate && !doc.validated ? (
              <Alert
                severity="warning"
                action={
                  <IconButton
                    disabled={!doc.canValidate}
                    onClick={() => onValidateDocument(doc.id)}
                  >
                    <UnpublishedIcon />
                  </IconButton>
                }
              >
                {dt.label} non validato
              </Alert>
            ) : null;
          })}
      {userProfile && userProfile?.hasC19 && userProfile?.isjr && (
        <>
          {userProfile.iscertc19ok === "In regola" ? (
            <>
              <Alert sx={{ mt: 1 }} severity="info">
                Autocertificazione COVID in regola
              </Alert>
              <Box>
                <Link
                  target="_blank"
                  referrerPolicy="no-referrer"
                  href={selfCertC19}
                >
                  Modulo autocertificazione COVID
                </Link>
                &nbsp;da caricare in area documenti ogni due settimane
              </Box>
            </>
          ) : (
            <>
              <Alert sx={{ mt: 1 }} severity="error">
                Autocertificazione COVID NON in regola:
                {userProfile.iscertc19ok}
              </Alert>
              <Box>
                Caricare&nbsp;
                <Link
                  target="_blank"
                  referrerPolicy="no-referrer"
                  href={selfCertC19}
                >
                  autocertificazione COVID
                </Link>
                &nbsp;in area documenti ogni due settimane
              </Box>
            </>
          )}
        </>
      )}
      {modUser && userProfile && (
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={2} padding={2}>
            {!userInfo?.hasAcl && infodialogsmap?.length > 0 && (
              <Grid item xs={12}>
                {infodialogsmap.map((id, idx) => (
                  <Button
                    style={{ marginRight: "2rem" }}
                    key={id.key}
                    variant="outlined"
                    color="info"
                    onClick={() => handleInfoDialog(idx, true)}
                  >
                    {id.label}
                  </Button>
                ))}
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h5">Dati area riservata</Typography>
            </Grid>

            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  id="username"
                  label="Username"
                  value={userProfile.nickname}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            {(userInfo?.isManager || userInfo?.isAdmin) && (
              <>
                <Grid item xs={6} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      id="enabled"
                      label="Abilitato"
                      value={localizeYesNo(userProfile.enabled)}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      id="tmpid"
                      label="Temp IID"
                      value={userProfile.tmpId}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {(userInfo?.isAdmin ||
              userInfo?.mgrDiscipline.includes(
                categoryDisciplines[userProfile?.category]
              )) && (
              <Grid item xs={1} md={1}>
                <Tooltip title="Diritti di accesso">
                  <IconButton
                    onClick={() => {
                      setAclDialogOpen(true);
                    }}
                  >
                    <ManageAccounts
                      size="small"
                      color={userInfo.hasAcl ? "success" : "disabled"}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {userProfile?.photourl && (
              <Grid item xs={1} md={1}>
                <Avatar
                  alt={userProfile?.name}
                  src={baseFileUrl + userProfile?.photourl}
                />
              </Grid>
            )}
            <Grid item xs={6} md={3}>
              <Button
                variant="contained"
                color={"primary"}
                onClick={() => setPwdDialogOpen(true)}
              >
                Modifica Password
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Dati utente</Typography>
            </Grid>
            <ModifyButtons
              locked={userProfile?.locked}
              lockedby={userProfile?.lockedbyusr}
              onEditUser={onEditUser}
              onUnlockProfile={onUnlockProfile}
              onUpdateProfile={onUpdateProfile}
            />
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id="surname"
                  label="Cognome"
                  type="text"
                  value={modUser.surname}
                  variant="standard"
                  disabled={userProfile?.locked}
                  onChange={(e) => onChangeUserParam("surname", e.target.value)}
                  required={!userInfo?.isManager && !userInfo?.isAdmin}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id="name"
                  label="Nome"
                  type="text"
                  value={modUser.name}
                  variant="standard"
                  disabled={userProfile?.locked}
                  onChange={(e) => onChangeUserParam("name", e.target.value)}
                  required={!userInfo?.isManager && !userInfo?.isAdmin}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl>
                <FormLabel id="gender">Sesso</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="gender-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={modUser.gender}
                  onChange={(e) => onChangeUserParam("gender", e.target.value)}
                >
                  <FormControlLabel
                    value="M"
                    control={<Radio />}
                    label="Maschile"
                    disabled={userProfile?.locked}
                  />
                  <FormControlLabel
                    value="F"
                    control={<Radio />}
                    label="Femminile"
                    disabled={userProfile?.locked}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  id="email"
                  label="E-mail"
                  type="email"
                  value={modUser.email}
                  variant="standard"
                  disabled={userProfile?.locked}
                  onChange={(e) => onChangeUserParam("email", e.target.value)}
                  required={!userInfo?.isManager && !userInfo?.isAdmin}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  id="mobilenr"
                  label="Cellulare"
                  type="tel"
                  value={modUser.phonenr}
                  variant="standard"
                  disabled={userProfile?.locked}
                  onChange={(e) => onChangeUserParam("phonenr", e.target.value)}
                  required={!userInfo?.isManager && !userInfo?.isAdmin}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  id="homenr"
                  label="Telefono casa"
                  type="tel"
                  value={modUser.homenr}
                  variant="standard"
                  disabled={userProfile?.locked}
                  onChange={(e) => onChangeUserParam("homenr", e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  id="CF"
                  label="Codice Fiscale"
                  type="text"
                  value={modUser.cf}
                  variant="standard"
                  disabled={userProfile?.locked}
                  onChange={(e) => onChangeUserParam("cf", e.target.value)}
                  required={!userInfo?.isManager && !userInfo?.isAdmin}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <MobileDatePicker
                  inputFormat="DD/MM/YYYY"
                  id="birthdate"
                  label="Data di nascita"
                  type="text"
                  value={modUser.birthdateD}
                  disabled={userProfile?.locked}
                  onChange={(value) => onChangeUserParam("birthdateD", value)}
                  required={!userInfo?.isManager && !userInfo?.isAdmin}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={9} md={6}>
              <FormControl fullWidth>
                <TextField
                  id="birthloc"
                  label="Luogo di nascita"
                  type="text"
                  value={modUser.birthloc}
                  variant="standard"
                  disabled={userProfile?.locked}
                  onChange={(e) =>
                    onChangeUserParam("birthloc", e.target.value)
                  }
                  required={!userInfo?.isManager && !userInfo?.isAdmin}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} md={3}>
              <FormControl fullWidth>
                <TextField
                  id="birthprov"
                  label="Provincia di nascita"
                  type="text"
                  value={modUser.birthprov}
                  variant="standard"
                  disabled={userProfile?.locked}
                  onChange={(e) =>
                    onChangeUserParam("birthprov", e.target.value)
                  }
                  required={!userInfo?.isManager && !userInfo?.isAdmin}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id="city"
                  label="Residenza"
                  type="text"
                  value={modUser.city}
                  variant="standard"
                  disabled={userProfile?.locked}
                  onChange={(e) => onChangeUserParam("city", e.target.value)}
                  required={!userInfo?.isManager && !userInfo?.isAdmin}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} md={2}>
              <FormControl fullWidth>
                <TextField
                  id="prov"
                  label="Provincia di residenza"
                  type="text"
                  value={modUser.prov}
                  variant="standard"
                  disabled={userProfile?.locked}
                  onChange={(e) => onChangeUserParam("prov", e.target.value)}
                  required={!userInfo?.isManager && !userInfo?.isAdmin}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id="address"
                  label="Indirizzo"
                  type="text"
                  value={modUser.address}
                  variant="standard"
                  disabled={userProfile?.locked}
                  onChange={(e) => onChangeUserParam("address", e.target.value)}
                  required={!userInfo?.isManager && !userInfo?.isAdmin}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} md={2}>
              <FormControl fullWidth>
                <TextField
                  id="postcode"
                  label="CAP"
                  type="text"
                  value={modUser.postcode}
                  variant="standard"
                  disabled={userProfile?.locked}
                  onChange={(e) =>
                    onChangeUserParam("postcode", e.target.value)
                  }
                  required={!userInfo?.isManager && !userInfo?.isAdmin}
                />
              </FormControl>
            </Grid>

            {userInfo?.hasAcl && (
              <>
                <ModifyButtons
                  locked={userProfile?.locked}
                  lockedby={userProfile?.lockedbyusr}
                  onEditUser={onEditUser}
                  onUnlockProfile={onUnlockProfile}
                  onUpdateProfile={onUpdateProfile}
                />
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Dati riservati alla Polisportiva
                  </Typography>
                </Grid>
                {!userProfile?.isinternal && (
                  <Grid item xs={6} md={4}>
                    <FormControl fullWidth>
                      <MobileDatePicker
                        inputFormat="DD/MM/YYYY"
                        id="signup"
                        label="Iscrizione"
                        type="text"
                        value={modUser.signupdateD}
                        disabled={userProfile?.locked || !userInfo.isManager}
                        onChange={(value) =>
                          onChangeUserParam("signupdateD", value)
                        }
                        required={!userInfo?.isManager && !userInfo?.isAdmin}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={6} md={4}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="category">Posizione</InputLabel>
                    <Select
                      id="category"
                      label="Posizione"
                      value={modUser.category}
                      disabled={userProfile?.locked || !userInfo.isManager}
                      //variant="standard"
                      onChange={(e) =>
                        onChangeUserParam("category", e.target.value)
                      }
                    >
                      {allowedCategories()}
                    </Select>
                  </FormControl>
                </Grid>
                {!userProfile?.isinternal && (
                  <Grid
                    item
                    xs={userProfile.isjr ? 4 : 6}
                    md={userProfile.isjr ? 2 : 4}
                  >
                    <FormControl fullWidth>
                      <MobileDatePicker
                        inputFormat="DD/MM/YYYY"
                        id="certexpire"
                        label="Scadenza certificato medico"
                        value={modUser.certexpireD}
                        disabled={userProfile?.locked || !userInfo.isManager}
                        onChange={(value) =>
                          onChangeUserParam("certexpireD", value)
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            error={userProfile.certerr !== ""}
                            helperText={
                              modUser.certexpire !== "" &&
                              !modUser.certvalidated
                                ? "Non validato"
                                : userProfile.certerr
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
            {userProfile.isjr && userInfo?.hasAcl && (
              <Grid item xs={2} md={1}>
                <FormControl
                  fullWidth
                  variant="standard"
                  disabled={userProfile?.locked || !userInfo.isManager}
                >
                  <FormControlLabel
                    label={process.env.REACT_APP_COMPLABEL || "Competitivo"}
                    control={
                      <Checkbox
                        checked={modUser.compcert > 0}
                        onChange={(e) =>
                          onChangeUserParam(
                            "compcert",
                            e.target.checked,
                            "checkbox"
                          )
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {userInfo?.hasAcl && !userProfile?.isinternal && (
              <>
                <Grid item xs={6} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="uispcard"
                      label="Tessera UISP"
                      type="text"
                      value={modUser.uispcard}
                      variant="standard"
                      disabled={userProfile?.locked || !userInfo.isManager}
                      onChange={(e) =>
                        onChangeUserParam("uispcard", e.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FormControl fullWidth>
                    <MobileDatePicker
                      inputFormat="DD/MM/YYYY"
                      id="uispexpire"
                      label="Scadenza Tessera UISP"
                      type="text"
                      value={modUser.uispexpireD}
                      disabled={userProfile?.locked || !userInfo.isManager}
                      onChange={(value) =>
                        onChangeUserParam("uispexpireD", value)
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          error={userProfile.uisperr !== ""}
                          helperText={userProfile.uisperr}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="uispcat"
                      label="Categoria UISP"
                      type="text"
                      value={userProfile.catuisp}
                      variant="standard"
                      disabled={userProfile?.locked || !userInfo.isManager}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="fidalcard"
                      label="Tessera FIDAL"
                      type="text"
                      value={modUser.fidalcard}
                      variant="standard"
                      disabled={userProfile?.locked || !userInfo.isManager}
                      onChange={(e) =>
                        onChangeUserParam("fidalcard", e.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FormControl fullWidth>
                    <MobileDatePicker
                      inputFormat="DD/MM/YYYY"
                      id="fidalexpire"
                      label="Scadenza Tessera FIDAL"
                      type="text"
                      value={modUser.fidalexpireD}
                      disabled={userProfile?.locked || !userInfo.isManager}
                      onChange={(value) =>
                        onChangeUserParam("fidalexpireD", value)
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          error={userProfile.fidalerr !== ""}
                          helperText={userProfile.fidalerr}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="fidalcat"
                      label="Categoria FIDAL"
                      type="text"
                      value={userProfile.catfidal}
                      variant="standard"
                      disabled={userProfile?.locked || !userInfo.isManager}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {userProfile?.isjr && userInfo?.hasAcl && (
              <>
                <ModifyButtons
                  locked={userProfile?.locked}
                  lockedby={userProfile?.lockedbyusr}
                  onEditUser={onEditUser}
                  onUnlockProfile={onUnlockProfile}
                  onUpdateProfile={onUpdateProfile}
                />
                <Grid item xs={12}>
                  <Typography variant="h5">{discName}</Typography>
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      id="ystart"
                      label="Anno Inizio"
                      type="text"
                      value={modUser.ystart}
                      variant="standard"
                      disabled={userProfile?.locked || !userInfo.isManager}
                      onChange={(e) =>
                        onChangeUserParam("ystart", e.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      id="yend"
                      label="Anno Fine"
                      type="text"
                      value={modUser.yend}
                      variant="standard"
                      disabled={userProfile?.locked || !userInfo.isManager}
                      onChange={(e) =>
                        onChangeUserParam("yend", e.target.value)
                      }
                    />
                  </FormControl>
                </Grid>

                {groupNames && Object.entries(groupNames)?.length > 0 && (
                  <Grid item xs={6} md={4}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel htmlFor="group">Gruppo</InputLabel>
                      <Select
                        id="group"
                        label="Gruppo"
                        value={modUser.group}
                        disabled={userProfile?.locked || !userInfo.isManager}
                        //variant="standard"
                        onChange={(e) =>
                          onChangeUserParam("group", e.target.value)
                        }
                      >
                        {Object.entries(groupNames)
                          .filter(
                            ([key, value]) =>
                              value.location === modUser?.location
                          )
                          .map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {locations?.length === 0 && (
                  <Grid item xs={6} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        id="location"
                        label="Palestra"
                        type="text"
                        value={modUser.location}
                        variant="standard"
                        disabled={userProfile?.locked || !userInfo.isManager}
                        onChange={(e) =>
                          onChangeUserParam("location", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
                {hasTrainingDays && (
                  <Grid item xs={12} md={6}>
                    <FormControl
                      required={!userInfo?.isManager && !userInfo?.isAdmin}
                      error={trainingsError() != null}
                      component="fieldset"
                      variant="standard"
                      disabled={userProfile?.locked || !userInfo.isManager}
                    >
                      <FormLabel id="trainings">Allenamenti</FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(modUser.trainings & 0x01) > 0}
                              onChange={handleChangeTraining}
                              name="1"
                            />
                          }
                          label="Lunedi"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(modUser.trainings & 0x02) > 0}
                              onChange={handleChangeTraining}
                              name="2"
                            />
                          }
                          label="Martedi"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(modUser.trainings & 0x04) > 0}
                              onChange={handleChangeTraining}
                              name="4"
                            />
                          }
                          label="Mercoledi"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(modUser.trainings & 0x08) > 0}
                              onChange={handleChangeTraining}
                              name="8"
                            />
                          }
                          label="Venerdi"
                        />
                        <FormHelperText>{trainingsError()}</FormHelperText>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}
                {locations?.length > 0 && (
                  <>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="location">Campo sportivo</InputLabel>
                        <Select
                          id="location"
                          label="Campo Sportivo"
                          value={modUser.location}
                          disabled={userProfile?.locked || !userInfo.isManager}
                          onChange={(e) =>
                            onChangeUserParam("location", e.target.value)
                          }
                        >
                          {locations?.map((loc) => (
                            <MenuItem key={loc.id} value={loc.id}>
                              {loc.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
                <Grid item xs={6} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="association"
                      label="Società"
                      type="text"
                      value={modUser.association}
                      variant="standard"
                      disabled={userProfile?.locked || !userInfo.isManager}
                      onChange={(e) =>
                        onChangeUserParam("association", e.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                {hasTShirt && (
                  <Grid item xs={2} md={2}>
                    <FormControl
                      fullWidth
                      variant="standard"
                      disabled={userProfile?.locked || !userInfo.isManager}
                    >
                      <FormControlLabel
                        label="Maglia"
                        control={
                          <Checkbox
                            checked={modUser.tshirt > 0}
                            onChange={(e) =>
                              onChangeUserParam(
                                "tshirt",
                                e.target.checked,
                                "checkbox"
                              )
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
            {userProfile.canManage &&
              userProfile.assocyear !== "" &&
              !userProfile?.isinternal &&
              userInfo?.hasAcl && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">Dati Iscrizione</Typography>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        id="association2"
                        label="Tesseramento"
                        value={`${userProfile.assocyear}: ${userProfile.assoctype}`}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        id="assocexpire"
                        label="Scadenza"
                        value={
                          userProfile.uispexpire !== ""
                            ? userProfile.uispexpire
                            : userProfile.fidalexpire
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <FormControl fullWidth>
                      <TextField
                        id="assoccomment"
                        label="Note"
                        value={userProfile.assoccomment}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        id="assoceuro"
                        label="Importo da dare"
                        value={userProfile.assoceuro}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <FormControl fullWidth>
                      <TextField
                        id="assocpaid"
                        label="Importo versato"
                        value={userProfile.assocpaid}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={4} />
                  <ModifyButtons
                    locked={userProfile?.locked}
                    lockedby={userProfile?.lockedbyusr}
                    onEditUser={onEditUser}
                    onUnlockProfile={onUnlockProfile}
                    onUpdateProfile={onUpdateProfile}
                  />
                </>
              )}
          </Grid>
        </Box>
      )}
      {userProfile?.parents && userProfile?.parents.length > 0 && (
        <>
          <Box
            //height={80 * (userProfile.parents.length + 1)}
            margin={2}
            flex={true}
            flexGrow="1"
          >
            <MUIDataTable
              title={
                <Box>
                  Genitori
                  {userProfile?.locked === false && (
                    <IconButton onClick={handleAddParent}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  )}
                </Box>
              }
              data={userProfile.parents}
              columns={parentColumns}
              options={{
                selectableRowsHideCheckboxes: true,
                pagination: false,
                download: false,
                print: false,
                viewColumns: false,
                filter: false,
                search: false,
                sort: false,
              }}
            />
          </Box>
        </>
      )}
    </>
  );
}
