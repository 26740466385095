import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadUsers } from "../actions/usersActions";
import {
  deleteUser,
  setUserAcl,
  setUserEnable,
  unloadProfile,
  updatetransfer,
} from "../actions/userActions";
import {
  Alert,
  Box,
  Button,
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PaymentIcon from "@mui/icons-material/Payment";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { Send, CancelScheduleSend, FileDownload } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { AclDialog } from "../components/AclDialog";
import { canTransfer, defaultCurrentFilter, publicName } from "../utils/config";
import { loadCategories, loadParams } from "../actions/paramActions";
import moment from "moment";
import { Workbook } from "exceljs";
import FileSaver from "file-saver";

const useStyles = makeStyles({
  filterTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "4px 4px 4px 4px",
    },
  },

  // mui-datatable cell
  uisperr: {
    backgroundColor: "#FC4",
  },
  fidalerr: {
    backgroundColor: "#FAA",
  },
  certerr: {
    backgroundColor: "#F88",
  },
  c19err: {
    backgroundColor: "#FF8",
  },
  // mui-datatable row
  disabled: {
    "& td": { backgroundColor: "#AAA" },
  },
  // entire table
  deftable: {
    "& td": { fontSize: "0.8rem", padding: 2, textAlign: "center" },
  },
});

export default function UsersScreen() {
  const [filteredUsers, setFilteredUsers] = useState([]);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const paramList = useSelector((state) => state.paramList);
  const { categories, disciplines, allgroups, alllocations, alllocPrefs } =
    paramList;

  const [filter, setFilter] = useState(
    localStorage.getItem("usersFilter")
      ? JSON.parse(localStorage.getItem("usersFilter"))
      : {
          current: defaultCurrentFilter,
          uisp: false,
          fidal: false,
          mf: null,
          jr: -1,
          discipline: "",
          category: "",
        }
  );

  const [aclDialogOpen, setAclDialogOpen] = useState(false);
  const [aclUser, setAclUser] = useState(null);

  const [categoryNames, setCategoryNames] = useState([]);
  const [categoryDisciplines, setCategoryDisciplines] = useState([]);

  const usersList = useSelector((state) => state.usersList);
  const { userProfiles, loading, error, message } = usersList;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();

  function changeFilter(newValue) {
    let newfilter = { ...filter, ...newValue };
    setFilter(newfilter);
    localStorage.setItem("usersFilter", JSON.stringify(newfilter));
  }

  function renderFirst(value, { rowData }, updateValue) {
    const user = filteredUsers.find(
      (u) => parseInt(u.id) === parseInt(rowData[0])
    );

    return (
      <Stack direction="row">
        <Tooltip title="Modifica Dati Utente">
          <IconButton
            size="small"
            onClick={() => navigate(`/profile/${user.id}`)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Modifica Dati Iscrizione">
          <IconButton onClick={() => navigate(`/profile/${user.id}/payments`)}>
            <PaymentIcon size="small" />
          </IconButton>
        </Tooltip>
        {(userInfo?.isAdmin ||
          userInfo?.mgrDiscipline.includes(
            categoryDisciplines[user?.category]
          )) && (
          <Tooltip title="Diritti di accesso">
            <IconButton
              onClick={() => {
                setAclUser(user);
                setAclDialogOpen(true);
              }}
            >
              <ManageAccountsIcon
                size="small"
                color={user.hasAcl ? "success" : "disabled"}
              />
            </IconButton>
          </Tooltip>
        )}
        <Box fontSize={"0.8rem"} margin="auto">
          {`(${user.id}${!user.locked ? " **" : ""}${
            !user.enabled ? " [D]" : ""
          })`}
        </Box>
      </Stack>
    );
  }

  function renderActions(value, { rowData, columnIndex }, updateValue) {
    const user = filteredUsers.find(
      (u) => parseInt(u.id) === parseInt(rowData[0])
    );

    return (userInfo?.acl !== undefined &&
      userInfo.acl[user.category] === "full") ||
      userInfo?.isAdmin ? (
      <Stack direction="row">
        <Tooltip title="Modifica Abilitazione">
          <IconButton onClick={() => onEnableUser(user.id, !user.enabled)}>
            {user.enabled ? (
              <ToggleOnIcon size="small" color="success" />
            ) : (
              <ToggleOffIcon size="small" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="Elimina Utente">
          <IconButton onClick={() => onDeleteUser(user.id)}>
            <DeleteIcon size="small" />
          </IconButton>
        </Tooltip>
        {user.transfer === "TRANSFERED" || !canTransfer ? (
          canTransfer && (
            <IconButton>
              <Send size="small" color="disabled" />
            </IconButton>
          )
        ) : (
          <Tooltip
            title={
              user.transfer === "READY"
                ? "Trasferisci Utente"
                : "Annulla Trasferimento"
            }
          >
            <IconButton
              onClick={() =>
                onPrepareTransfer(user.id, user.transfer === "READY")
              }
            >
              {user.transfer === "READY" && (
                <Send size="small" color="success" />
              )}
              {user.transfer === "SELECTED" && (
                <CancelScheduleSend size="small" color="warning" />
              )}
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    ) : null;
  }

  function tableProps() {
    return {
      className: classes.deftable,
    };
  }

  function rowProps(row, dataIndex) {
    const user = filteredUsers[dataIndex];

    return {
      className: clsx({
        [classes.disabled]: !user.enabled,
      }),
    };
  }

  function cellProps(cellValue, rowIndex, columnIndex) {
    const user = filteredUsers[rowIndex];

    return {
      className: clsx({
        [classes.c19err]:
          user.iscertc19ok === "Scaduta" && columnIndex === 7 && user.enabled,
        [classes.uisperr]:
          user.uisperr !== "" && columnIndex === 11 && user.enabled,
        [classes.fidalerr]:
          user.fidalerr !== "" && columnIndex === 12 && user.enabled,
      }),
    };
  }

  const usersColumns = [
    {
      name: "longid",
      label: "Iscrizione",
      options: { customBodyRender: renderFirst },
    },
    { name: "fullname", label: "Cognome e Nome" },
    { name: "assoctype", label: "Iscrizione" },
    { name: "assoccomment", label: "Note" },
    { name: "assocpaid", label: "Quota versata" },
    { name: "discname", label: "Sport" },
    { name: "categoryname", label: "Posizione" },
    { name: "group", label: "Gruppo" },
    { name: "locname", label: "Campo Sportivo" },
    {
      name: "locprefs",
      label: process.env.REACT_APP_LOCPREFTEXT || "Preferenze",
    },
    //{ name: "certexpire", label: "Scadenza Certificato" },
    {
      name: "certc19",
      label: "Cert. CoViD19",
      options: {
        setCellProps: cellProps,
      },
    },
    { name: "birthyear", label: "Anno di nascita" },
    // { name: "birthdate", label: "Data di nascita" },
    // { name: "birthloc", label: "Luogo di nascita" },
    { name: "email", label: "e-Mail" },
    { name: "phonenrs", label: "Telefono" },
    {
      name: "uisp",
      label: "UISP",
      options: { setCellProps: cellProps },
    },
    {
      name: "fidal",
      label: "FIDAL",
      options: { setCellProps: cellProps },
    },
    {
      name: "catuisp",
      label: "Cat. UISP",
    },
    {
      name: "catfidal",
      label: "Cat. FIDAL",
    },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderActions },
    },
  ];

  const CellFilter = ({
    param,
    name,
    value,
    ckvalue,
    param2 = "temp1",
    value2 = "none",
    param3 = "temp2",
    value3 = "none",
  }) => {
    const ck = ckvalue || value;

    return (
      <TableCell>
        <Button
          fullWidth={true}
          variant={
            filter[param] === ck &&
            (param2 === "temp1" || filter[param2] === value2) &&
            (param3 === "temp2" || filter[param3] === value3)
              ? "contained"
              : "outlined"
          }
          sx={{ fontSize: "0.65rem" }}
          onClick={() =>
            changeFilter({ [param]: value, [param2]: value2, [param3]: value3 })
          }
        >
          {name}
        </Button>
      </TableCell>
    );
  };

  function handleExport() {
    const exportUsers = filteredUsers
      .sort((a, b) =>
        a.surname < b.surname
          ? -1
          : a.surname > b.surname
          ? 1
          : a.name < b.name
          ? -1
          : 1
      )
      .map((u) => {
        const p =
          u.parents?.find((p) => p?.maincontact) ||
          (u?.parents?.length && u.parents[0]) ||
          u;

        return {
          ...u,
          email: p.email,
          phonenr: p.phonenr,
          birthcityprov: `${u.birthloc} (${u.birthprov})`,
          cityprov: `${u.city} (${u.prov})`,
          username: `${u.nickname} (${u.id})`,
        };
      });

    const workbook = new Workbook();
    workbook.creator = `Gestionale ${publicName}`;
    workbook.created = new Date();
    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet("Utenti");
    worksheet.columns = [
      {
        header: "COGNOME",
        key: "surname",
        width: 25,
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "20F0F0" },
          },
        },
      },
      {
        header: "NOME",
        key: "name",
        width: 25,
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "20F0F0" },
          },
        },
      },
      { header: "Sesso", key: "gender", width: 8 },
      { header: "e-Mail", key: "email", width: 40 },
      { header: "Telefono", key: "phonenr", width: 15 },
      { header: "Data di nascita", key: "birthdate", width: 13 },
      { header: "Luogo di nascita", key: "birthcityprov", width: 30 },
      { header: "Codice Fiscale", key: "cf", width: 25 },
      { header: "Residenza", key: "cityprov", width: 35 },
      { header: "Indirizzo", key: "address", width: 35 },
      { header: "Sport", key: "discname", width: 15 },
      { header: "Posizione", key: "categoryname", width: 15 },
      { header: "Gruppo", key: "group", width: 20 },
      { header: "Campo Sportivo", key: "locname", width: 25 },
      {
        header: process.env.REACT_APP_LOCPREFTEXT || "Preferenze",
        key: "locprefs",
        width: 25,
      },
      { header: "Iscrizione", key: "assoctype", width: 20 },
      { header: "Commenti", key: "assoccomment", width: 20 },
      { header: "Quota versata", key: "assocpaid", width: 12 },
      { header: "Nome utente", key: "username", width: 35 },
      { header: "UISP", key: "uispcard", width: 12 },
      { header: "FIDAL", key: "fidalcard", width: 12 },
      {
        header: "Scadenza Certificato Medico",
        key: "certexpire",
        width: 17,
      },
    ];

    worksheet.addRows(exportUsers);
    worksheet.autoFilter = {
      from: {
        row: 1,
        column: 1,
      },
      to: {
        row: exportUsers.length + 1,
        column: worksheet.columns.length,
      },
    };

    worksheet.views = [
      {
        state: "frozen",
        xSplit: 2,
        ySplit: 1,
        topLeftCell: "C2",
        activeCell: "A1",
      },
    ];

    const headRow = worksheet.getRow(1);
    headRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFA0C0" },
    };

    const signupCol = worksheet.getColumn("assoctype");
    const certCol = worksheet.getColumn("certexpire");

    exportUsers.forEach((u, index) => {
      if (u.uisperr !== "" || u.fidalerr !== "" || u.assoctype === "") {
        worksheet.getCell(index + 2, signupCol.number).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "D02020" },
        };
      }

      if (u.certerr) {
        worksheet.getCell(index + 2, certCol.number).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "D02020" },
        };
      }

      if (!u.enabled) {
        const uRow = worksheet.getRow(index + 2);
        uRow.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "C0C0C0" },
        };
      }
    });

    const date = moment().format("YYYY-MM-DD");

    workbook.xlsx.writeBuffer().then((data) => {
      const blobType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const blob = new Blob([data], { type: blobType });
      FileSaver.saveAs(blob, `Elenco Utenti ${publicName}_${date}.xlsx`);
    });
  }

  function onEnableUser(userId, enable) {
    dispatch(setUserEnable(userId, enable));
  }

  function onDeleteUser(userId) {
    dispatch(deleteUser(userId));
  }

  function onAclDialogClose(save, user) {
    if (save) {
      dispatch(setUserAcl(user.id, user.acl));
    }

    setAclDialogOpen(false);
    setAclUser(null);
  }

  function onPrepareTransfer(userId, dotransfer) {
    dispatch(updatetransfer(userId, dotransfer ? "SELECTED" : "READY"));
  }

  useEffect(() => {
    if (userProfiles?.length === 0) dispatch(loadUsers());
  }, [dispatch, userProfiles]);

  useEffect(() => {
    if (!disciplines?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, disciplines]);

  useEffect(() => {
    if (!categories?.length) {
      dispatch(loadCategories());
    } else {
      let names = [];
      categories.forEach((cat) => (names[cat.key] = cat.description));
      setCategoryNames(names);
      let catDisciplines = [];
      categories.forEach((cat) => (catDisciplines[cat.key] = cat.discipline));
      setCategoryDisciplines(catDisciplines);
    }
  }, [dispatch, categories]);

  useEffect(() => {
    dispatch(unloadProfile());
    if (!userProfiles || userProfiles.length < 1) return;

    var filterfinal = [...userProfiles];

    if (filter.discipline) {
      filterfinal = filterfinal.filter(
        (user) => categoryDisciplines[user.category] === filter.discipline
      );
    }
    if (filter.category) {
      filterfinal = filterfinal.filter(
        (user) => user.category === filter.category
      );
    }
    if (filter.jr !== -1) {
      filterfinal = filterfinal.filter(
        (user) => user.isjr === (filter.jr === 1)
      );
    }
    if (filter.current) {
      filterfinal = filterfinal.filter(
        (user) => user.assocyear === userInfo.currentYear
      );
    }
    if (filter.uisp) {
      filterfinal = filterfinal.filter((user) => user.uispcard !== "");
    }
    if (filter.fidal) {
      filterfinal = filterfinal.filter((user) => user.fidalcard !== "");
    }
    if (filter.mf) {
      filterfinal = filterfinal.filter((user) => user.gender === filter.mf);
    }

    const filtermap = filterfinal.map((user) => {
      return {
        ...user,
        fullname: `${user.surname} ${user.name}`,
        categoryname: categoryNames[user.category],
        discname:
          disciplines.find(
            (disc) => disc.id === categoryDisciplines[user.category]
          )?.name || "?",
        assoctype:
          user.assocyear > 0 ? `${user.assoctype} (${user.assocyear})` : "",
        assocyear: user.assocyear > 0 ? user.assoccomment : "",
        assocpaid:
          user.assocyear > 0 ? `${user.assocpaid} / ${user.assoceuro}` : "",
        certc19:
          user.certc19date !== "NA"
            ? `${user.iscertc19ok} (${user.certc19date})`
            : "--",
        fidal: `${user.fidalcard} (${user.fidalexpire})`,
        uisp: `${user.uispcard} (${user.uispexpire})`,
        phonenrs: `${user.homenr} ${user?.phonenr && user.phonenr}`,
        birthyear: user.birthdate.substr(-4),
        group:
          user.group && allgroups
            ? allgroups[user.group]?.name
              ? allgroups[user.group].name
              : `Gruppo: ${user.group}`
            : user.location
            ? `C.s.: ${user.location}`
            : "--",
        locname:
          user.location && alllocations
            ? alllocations[user.location]?.name
              ? alllocations[user.location].name
              : `C.s.: ${user.location}`
            : "--",
        locprefs:
          user?.locprefs && alllocPrefs
            ? user.locprefs
                .split(",")
                .map((lp) => {
                  const id = `${categoryDisciplines[
                    user.category
                  ]?.toUpperCase()}_${lp}`;
                  return alllocPrefs[id]?.name || "?";
                })
                .join(",")
            : "",
      };
    });

    setFilteredUsers(filtermap);
  }, [
    userProfiles,
    allgroups,
    alllocPrefs,
    alllocations,
    categoryDisciplines,
    categoryNames,
    disciplines,
    filter,
    userInfo?.currentYear,
    dispatch,
  ]);

  return (
    <>
      {loading && <LinearProgress />}
      {message && <Alert severity="info">{message}</Alert>}
      {error && <Alert color="error">{error}</Alert>}
      <AclDialog
        dlgOpen={aclDialogOpen}
        aclUser={aclUser}
        onConfirm={onAclDialogClose}
      />
      {filteredUsers && (
        <>
          <Box width={"100%"}>
            <Table
              sx={{
                width: "auto",
                margin: "auto",
                backgroundColor: "white",
                mt: 2,
              }}
              classes={{ root: classes.filterTable }}
              size="small"
            >
              <TableBody>
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <Chip
                      sx={{ fontSize: "0.8rem" }}
                      variant="outlined"
                      label={`Utenti mostrati ${filteredUsers.length} / ${userProfiles?.length}`}
                      onClick={() =>
                        changeFilter({
                          current: defaultCurrentFilter,
                          uisp: false,
                          fidal: false,
                          mf: null,
                          jr: -1,
                          category: "",
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleExport()}>
                      <FileDownload />
                    </IconButton>
                  </TableCell>
                  <CellFilter
                    param="jr"
                    value={-1}
                    param2="category"
                    value2=""
                    param3="discipline"
                    value3=""
                    name="Tutti"
                  />
                  {disciplines &&
                    userInfo?.aclDiscipline?.length > 0 &&
                    disciplines
                      ?.filter((disc) =>
                        userInfo?.aclDiscipline.includes(disc.id)
                      )
                      .map((disc) => (
                        <CellFilter
                          key={disc.id}
                          param="discipline"
                          value={disc.id}
                          name={disc.name}
                        />
                      ))}
                </TableRow>
                <TableRow>
                  <CellFilter param="mf" value="M" name="Maschi" />
                  <CellFilter param="mf" value={null} name="M/F" />
                  <CellFilter param="mf" value="F" name="Femmine" />
                  <CellFilter
                    param="current"
                    ckvalue={true}
                    value={!filter.current}
                    name={filter.current ? "In corso" : "Storico"}
                  />
                  <CellFilter
                    param="jr"
                    value={1}
                    param2="category"
                    value2=""
                    name={process.env.REACT_APP_JRLABEL || "Junior"}
                  />
                  {categories
                    ?.filter((cat) => cat.isjr === "1")
                    ?.map((cat) =>
                      userInfo?.acl[cat.key] !== "none" || userInfo?.isAdmin ? (
                        <CellFilter
                          key={cat.key}
                          param="category"
                          value={cat.key}
                          param2="jr"
                          value2={-1}
                          name={cat.description}
                        />
                      ) : null
                    )}
                </TableRow>
                <TableRow>
                  <CellFilter
                    param="fidal"
                    ckvalue={true}
                    value={!filter.fidal}
                    name="FIDAL"
                  />
                  <TableCell />
                  <CellFilter
                    param="uisp"
                    ckvalue={true}
                    value={!filter.uisp}
                    name="UISP"
                  />
                  <TableCell />
                  <CellFilter
                    param="jr"
                    value={0}
                    param2="category"
                    value2=""
                    name="Senior"
                  />
                  {categories
                    ?.filter((cat) => cat.isjr === "0")
                    ?.map((cat) =>
                      userInfo?.acl[cat.key] !== "none" || userInfo?.isAdmin ? (
                        <CellFilter
                          key={cat.key}
                          param="category"
                          value={cat.key}
                          param2="jr"
                          value2={-1}
                          name={cat.description}
                        />
                      ) : null
                    )}
                </TableRow>
              </TableBody>
            </Table>
          </Box>

          <Box margin={2} flex={true} flexGrow="1">
            <MUIDataTable
              data={filteredUsers}
              columns={usersColumns}
              options={{
                selectableRowsHideCheckboxes: true,
                download: false,
                // print: false,
                searchAlwaysOpen: true,
                selectableRows: "none",
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 50, 100],
                setTableProps: tableProps,
                setRowProps: rowProps,
              }}
            />
          </Box>
        </>
      )}
    </>
  );
}
