import { configureStore } from "@reduxjs/toolkit";

import { documentReducer } from "./reducers/docReducer";
import { paramReducer } from "./reducers/paramReducers";
import { transferReducer } from "./reducers/transferReducer";
import { userSigninReducer, userProfileReducer } from "./reducers/userReducers";
import { userProfilesReducer } from "./reducers/usersReducer";
import { attendeeReducer } from "./reducers/attReducer";

const store = configureStore({
  reducer: {
    userSignin: userSigninReducer,
    userDetails: userProfileReducer,
    usersList: userProfilesReducer,
    attendee: attendeeReducer,
    documentList: documentReducer,
    paramList: paramReducer,
    transferStore: transferReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export default store;
