import { Box, Link } from "@mui/material";
import { createStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { loadProfile, signout, unloadProfile } from "../actions/userActions";
import { publicName } from "../utils/config";
import LoginScreen from "./LoginScreen";

const styles = createStyles({
  header: {
    marginBottom: "43px",
    height: "170px",
    backgroundColor: process.env.REACT_APP_BGCOLOR || "#EAFFD8",
    backgroundImage: `url('${
      process.env.PUBLIC_URL + "/" + process.env.REACT_APP_MAINIMAGE_URL
    }')`,
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: process.env.REACT_APP_MAINIMAGE_WIDTH,
  },
});

const Banner = () => {
  return (
    <Box
      sx={{
        marginTop: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box style={styles.header} />
    </Box>
  );
};

export default function HomeScreen() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo && userInfo?.enabled === true) {
      if (userInfo?.isParent) dispatch(loadProfile(userInfo.childid));
      else dispatch(loadProfile(userInfo.id));
    }
    else {
      dispatch(signout());
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(unloadProfile());
    if (
      userInfo?.id &&
      userInfo?.enabled === true &&
      location?.state?.from !== undefined
    ) {
      navigate(location.state.from);
    } else if (userInfo?.id && userInfo?.enabled === true && userInfo?.tmpID) {
      if (userInfo?.isParent)
        navigate(`/profile/${userInfo.childid}/parents/${userInfo.id}`);
      else navigate(`/profile/${userInfo.id}`);
    }
  }, [userInfo, location, navigate, dispatch]);

  return userInfo?.id === undefined ? (
    <Box>
      <Banner />
      <LoginScreen />
    </Box>
  ) : (
    <Box>
      <Banner />
      <Box>
        Benvenuto {userInfo.name},<br />
        Questa è la nuova versione della area riservata della {publicName}.
        <br />
        Puoi navigare nelle varie funzioni utilizzando la barra in alto.
      </Box>
      {userInfo?.enabled === false && userInfo?.verifymailok === true && (
        <Box marginTop={3}>
          L'utente è disabilitato, verifica con l'amministratore del gestionale
        </Box>
      )}
      {userInfo?.verifymailok === false && (
        <Box marginTop={3}>
          Attualmente la mail non è confermata, dovresti aver ricevuto una mail.
          <br />
          Verifica la casella di posta, eventualmente anche la cartella spam.
          <br />
          Altrimenti puoi richiedere un nuovo invio da questa{" "}
          <Link
            href={`${process.env.PUBLIC_URL}/confirmmail/UID${userInfo.id}`}
          >
            pagina
          </Link>
        </Box>
      )}
    </Box>
  );
}
